<template>
  <div>
    <b-breadcrumb style="margin-bottom:20px">
      <b-breadcrumb-item to="/">
        <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
      </b-breadcrumb-item>
      
      <b-breadcrumb-item to="/po-fail" v-if="from=='/po-fail'"
        >PO Fail List</b-breadcrumb-item
      >
      <b-breadcrumb-item to="/order" v-else-if="permission.view"
        >Purchase Order List</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Detail Purchase</b-breadcrumb-item>
    </b-breadcrumb>
    <b-card no-body>
      <b-card-body title="Detail Purchase Order">
        <b-col cols="12">
          <b-row>
            <!-- ================================ -->
            <b-col cols="6">
              <b-row>
                <div class="d-flex flex-column">
                  <label>Purchase Order</label>
                  <p class="font-weight-bold">{{ poNumber[0] }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Customer PO</label>
                  <p class="font-weight-bold">{{ poNumber[1] }}</p>
                </div>
              </b-row>
              <b-row>
                <div
                  class="d-flex flex-column"
                  v-if="purchase.deliveryType === 'Internal Warehouse Delivery'"
                >
                  <label>Warehouse</label>
                  <p class="font-weight-bold">
                    {{ purchase.dealer.dealerName }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div
                  class="d-flex flex-column"
                >
                  <label>Industry Type</label>
                  <p class="font-weight-bold">
                    {{ purchase.distributionChannel }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Fullfillment Type</label>
                  <p class="font-weight-bold">
                    {{ purchase.orderType }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Order Status</label>
                  <p class="font-weight-bold">{{ purchase.orderStatus }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Purchase Date</label>
                  <p class="font-weight-bold">
                    {{ dates(purchase.updatedAt) }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Date</label>
                  <p class="font-weight-bold">
                    {{ dates(purchase.deliveryDate) }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Phone</label>
                  <p class="font-weight-bold">
                    {{ purchase.customerDetail.phone }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Email</label>
                  <p class="font-weight-bold">
                    {{ purchase.deliveryEmail }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Instruction</label>
                  <p class="font-weight-bold">
                    {{ purchase.deliveryInstruction }}
                  </p>
                </div>
              </b-row>
              
            </b-col>

            <b-col cols="6">
              <b-row>
                <div
                  class="d-flex flex-column"
                  v-if="purchase.deliveryType === 'End Customer'"
                >
                  <label>Customer Name</label>
                  <p class="font-weight-bold">
                    {{
                      purchase.customerDetail.type === "Company"
                        ? purchase.customerDetail.companyName
                        : `${purchase.customerDetail.firstName} ${purchase.customerDetail.lastName}`
                    }}
                  </p>
                </div>

                <div
                  class="d-flex flex-column"
                  v-if="purchase.deliveryType === 'Self Collect'"
                >
                  <label>Customer Name</label>
                  <p class="font-weight-bold">
                    {{
                      purchase.customerDetail.firstName === ""
                        ? "Not Set Up"
                        : `${purchase.customerDetail.firstName} ${purchase.customerDetail.lastName}`
                    }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Customer Address</label>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address">
                    {{ purchase.customerDetail.address }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address2">
                    {{ purchase.customerDetail.address2 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.customerDetail.address3">
                    {{ purchase.customerDetail.address3 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress">
                    {{ purchase.deliveryAddress }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress2">
                    {{ purchase.deliveryAddress2 }}
                  </p>
                  <p class="font-weight-bold" v-if="purchase.deliveryAddress3">
                    {{ purchase.deliveryAddress3 }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Delivery Type</label>
                  <p class="font-weight-bold">{{ purchase.deliveryType }}</p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>SAP SO</label>
                  <p class="font-weight-bold" v-if="this.purchase.sapSo === ''">
                    Not Set Up
                  </p>
                  <p size="md" v-if="this.purchase.sapSo !== ''">
                    {{ purchase.sapSo }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="d-flex flex-column">
                  <label>Remarks</label>
                  <p class="font-weight-bold">
                    {{ purchase.remarks }}
                  </p>
                </div>
              </b-row>
            </b-col>
          </b-row>
          <!-- ============================= -->
          <!-- <b-row>

            <b-col cols="6"> </b-col>

            <b-col cols="6">
              

              
            </b-col>
          </b-row> -->
          <!-- ================================ -->
          <!-- <b-row>
            <b-col cols="6"> </b-col>
            <b-col cols="6">
              
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6"> </b-col>

            <b-col cols="6">
             
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6"> </b-col>
            <b-col cols="6"> </b-col>
          </b-row> -->

          <b-row style="margin-top:25px;">
            <h5>Purchase Order Item List</h5>
            <b-table-simple>
              <b-tr variant="secondary">
                <b-th colspan="4" >Model</b-th>
                <b-th colspan="1" >Qty</b-th>
                <b-th colspan="3"  class="text-right">Unit Price</b-th>
                <b-th colspan="4"  class="text-right">Amount</b-th>
              </b-tr>
              <b-tr v-for="(item,index) in purchase.items" :key="item">
                <b-td colspan="4">{{ item.model }}</b-td>
                <b-td colspan="1">{{ item.qty }}</b-td>
                <b-td colspan="3"  class="text-right">{{ formatter(item.price.$numberDecimal) }}</b-td>
                <b-td colspan="4"  class="text-right">{{ formatter(parseInt(item.price.$numberDecimal)*item.qty) }}</b-td>
              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="8" >Subtotal</b-th>
                <b-td colspan="3" class="text-right">{{formatter(purchase.gstCalculation.total)}}</b-td>
              </b-tr>
              <b-tr>
                <b-th colspan="8">GST {{ purchase.gstCalculation.gst }}%</b-th>
                <b-td colspan="3"  class="text-right">{{formatter(purchase.gstCalculation.gst*purchase.gstCalculation.total/100)}} </b-td>

              </b-tr>
              <b-tr variant="secondary">
                <b-th colspan="8">Grand Total</b-th>
                <b-td colspan="3" class="text-right">{{formatter(purchase.gstCalculation.grandTotal)}}</b-td>

              </b-tr>
            </b-table-simple>
          </b-row>
          
          
          
          <b-row style="margin-top:25px;">
            <h5>Delivery Order Information</h5>
            <b-table
              :items="doList"
              :fields="doFields"
              responsive
            >
              <template #cell(sapDo)="row">
                <b-link size="md" @click="toDelivery(row.item)">
                  {{ row.item.sapDo }}
                </b-link>
              </template>
              <template #cell(status)="row">
                <step-progress-daikin :totalStep="(purchase.deliveryType=='Self Collect')?3:4" :status="row.item.status"></step-progress-daikin>
              </template>
            </b-table>
          </b-row>
        </b-col>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { userAccess, dateFormat } from "@/utils/utils";
import stepProgressDaikin from '@/components/StepProgressDaikin.vue'

export default {
  components: {
    stepProgressDaikin
  },
  props: ["id","from"],
  data() {
    return {
      doFields: [
        { key: "sapDo", label: "DO Number" },
        { key: "sapInvoice", label: "Invoice Number" },
        { key: "status", label: "Status" },
      ],
      itemFields: [
        { key: "model", text: "Item Model" },
        { key: "qty", text: "Qty" },
        { key: "price", text: "Price" },
        // { key: "rejectedQty", text: "Rejected Qty" },
        // { key: "rejectionReason", text: "Reason" },
      ],
      doList:[]
    };
  },
  methods: {
    remarksFormat(remarkString){
      if(remarkString==undefined){
        return ["",""]
      }
      var parsed= remarkString.split('\n');
      var lineOne= (parsed[0]==undefined)?"":parsed[0];
      var lineTwo= (parsed[1]==undefined)?"":parsed[1];
      
      return [lineOne,lineTwo]
    },
    dates(date) {
      return dateFormat(date);
    },
    customerName(purchase) {
      if (purchase.customer) {
        return purchase.customerDetail.nameFirst + " " + purchase.customerDetail.nameLast;
      } else {
        return (
          purchase.customerDetail.firstName +
          " " +
          purchase.customerDetail.lastName
        );
      }
    },
    formatter(value) {
      var number = value;
      if (!number) {
        number = 0;
      }
      return (Math.round(number * 100) / 100).toFixed(2);
    },
    async getDOList(sapSo) {
      //return [{sapDo:1,sapInvoice:1}]
      var filterDO = await this.$store.dispatch("delivery/getDeliveryOrder",{sapSo:'0000'+sapSo})
        if(filterDO.data.length<=0){
          console.info("undefined")
          this.doList=[]
          return
        }

        var jData = filterDO.data.map((value)=>{
          return {
            sapDo:value.sapDo,
            sapInvoice:value.sapInvoice,
            status:value.status
          }
        })
        console.info('data ',jData)
        console.info('filterDO ',filterDO.data)
        this.doList=jData
        return
      
      
    },
    toSales() {
      var sapSo = this.purchase.sapSo;
      var filterSO = this.salesOrders.find((x) => {
        return x.sapSo === sapSo;
      });
      this.$router.push({
        name: "sales-order-detail",
        params: { idSales: filterSO._id },
      });
    },
    toDelivery(doDetail) {
      this.$router.push({
        name: "warehouse-detail-delivery",
        params: { id: doDetail._id, from: "other" },
      });
    },
  },
  computed: {
    // deliveryOrders() {
    //   return this.$store.getters["delivery/getDO"];
    // },
    purchase() {
      return this.$store.getters["purchase/purchaseById"];
    },
    poNumber(){
      if(this.purchase.length<=0){
        return ["",""]
      }
      var split = this.purchase.PONumber.split("*");
      return split;
    },
    salesOrders() {
      return this.$store.getters["sales/sales"];
    },
    permission() {
      let result = userAccess("Order", "order_menu");
      // console.log("Order",result)
      return result;
    },
  },
  mounted() {
    //this.$store.dispatch("delivery/getDeliveryOrder");
    this.$store.dispatch("sales/getSales", { orderStatus: "ALL" });

    this.$store
      .dispatch("purchase/detailPurchase", {
        id: this.id,
      })
      .then((res)=>{
        this.getDOList(this.purchase.sapSo)
      })
      .catch((err) => {
        console.log({ err });
      });
  },
};
</script>
