<template>
  <div class="step-progress-container">
    <ul :class="stepProgressClass">
      <li :class="stepColor(i)" v-for="i in totalStep" :key="i">{{ stepText(i) }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  /**
    How to Use.
    1. Import this vue file to the desired vue file
    2. setup component
    3. 

    status: do status
  **/
  props: ['status', 'totalStep'],
  data() {
    return {};
  },
  computed: {
    finishedNumber() {
      var finishedStatus = ["Fulfilled", "Return Completed", "Collected","Returned","Waiting Approval","Waiting Acknowledgement","Approved"];
      var onDelivery = ["Delivery in Progress"];
      var progressFinished = [ "Edit",
        "Edit in Progress",
        "Scanning in Progress","Scanning Completed", "Re-Delivery","Cancel Delivery",
        "Delivery Canceled", "Delete Delivery" ];
      var onProgress = [
       
      ];

      if (finishedStatus.includes(this.status)) {
        return this.totalStep;
      }
      if (onDelivery.includes(this.status)) {
        return 3;
      }
      if (progressFinished.includes(this.status)) {
        return 2;
      }
      return 1;
    },
    isReturned() {
      var listReturn =["Return Completed","Returned","Waiting Approval","Waiting Acknowledgement","Approved"]
      return listReturn.includes(this.status) ;
    },
    isCanceled(){
      var listCancel =["Cancel Delivery","Delivery Canceled","Delete Delivery"];
      return listCancel.includes(this.status);
    },
    stepProgressClass() {
      return {
        "step-progress-bar": true,
        "three-steps": this.totalStep === 3,
        "four-steps": this.totalStep === 4
      };
    }
  },
  methods: {
    stepText(index) {
      var statusList = ["", "Create", "In Progress", "On Delivery"];

      if (index === this.totalStep) {
       return "Completed / Returned"
      }
      return statusList[index];
    },
    stepColor(index) {
      if (index <= 0) {
        return "";
      }
      if(this.isCanceled&&index>this.finishedNumber){
        return "";
      }
      if(index==2&&this.isCanceled){
        return "ng";
      }
      if (index - 1 === this.finishedNumber) {
        return "onprogress";
      }

      

      console.info('isReturned ',this.isReturned,this.finishedNumber,index);

      if (
        this.finishedNumber === index  &&
        this.isReturned
      ) {
        return "ng";
      }
      if (index <= this.finishedNumber) {
        return "active";
      }
      return "";
    }
  }
}
</script>

<style scoped>
.step-progress-container {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.step-progress-bar {
  list-style-type: none;
  counter-reset: step;
}

.step-progress-bar li {
  font-size: 1vw;
  float: left;
  position: relative;
  text-align: center;
}

.step-progress-bar li:before {
  content: ".";
  width: 25px;
  height: 25px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.step-progress-bar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 10px;
  left: -50%;
  z-index: -1;
}

.step-progress-bar li.active:before,
.step-progress-bar li.onprogress:before,
.step-progress-bar li.ng:before {
  content: "✔️";
  border-color: #3aac5d;
  background: #3aac5d;
  color: white;
}

.step-progress-bar li.active:after,
.step-progress-bar li.onprogress:after,
.step-progress-bar li.ng:after {
  background: #3aac5d;
}

.step-progress-bar li.onprogress:before {
  content: ".";
  border-color: #979797;
  background: #979797;
  color: white;
}

.step-progress-bar li.onprogress:after {
  background: #eeee00;
}

.step-progress-bar li.ng:before {
  content: "✔️";
  border-color: #db0f0fcc;
  background: #db0f0fcc;
  color: white;
}

.step-progress-bar li.ng:after {
  background: #db0f0fcc;
}

.step-progress-bar li:first-child:after {
  content: none;
}

.three-steps li {
  width: calc(100% / 3);
}

.four-steps li {
  width: calc(100% / 4);
}
</style>
